import React from "react";
import { Link } from "react-router-dom";
import { LogoName } from "../../Utility/constants";
import "./AdminHeader.css"; // Import the CSS file for custom styles

const AdminHeader = () => {
  const handleLogout = () => {
    // Clear user data from local storage
    localStorage.removeItem("userEmail");
    localStorage.removeItem("Role");
    localStorage.removeItem("userID");
    // Redirect to the login page or home page
    window.location.href = "/";
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container d-flex justify-content-between">
          <a
            className="navbar-brand"
            href="/admindashboard"
            style={{ color: "white" }}
          >
            {LogoName}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="manageMastersDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ color: "white" }}
                >
                  Manage Masters
                </a>
                <div
                  className="dropdown-menu bg-dark"
                  aria-labelledby="manageMastersDropdown"
                >
                  {/* <Link className="dropdown-item" to="/manage-attach-type">
                    Attach Type
                  </Link> 
                  <Link className="dropdown-item" to="/manage-blood-group">
                    Blood Group
                  </Link>
                  <Link className="dropdown-item" to="/manage-monther-tounge">
                    Mother Tounge
                  </Link>
                   <Link className="dropdown-item" to="/manage-student-type">
                    Student Type
                  </Link>
                  */}
                  <Link className="dropdown-item" to="/manage-category">
                    Category
                  </Link>
                  <Link className="dropdown-item" to="/manage-sub-category">
                  Caste
                  </Link>
                  <Link className="dropdown-item" to="/manage-religion">
                    Religion
                  </Link>

                  <Link className="dropdown-item" to="/manage-state">
                    State
                  </Link>
                  <Link className="dropdown-item" to="/manage-district">
                    District
                  </Link>
                  <Link className="dropdown-item" to="/manage-taluka">
                    Taluka
                  </Link>
                  <Link className="dropdown-item" to="/manage-city">
                    City-Vlllage
                  </Link>

                  <Link className="dropdown-item" to="/manage-grant-type">
                    Grant Type
                  </Link>
                  
                 
                  <Link className="dropdown-item" to="/manage-school-timing">
                    School Timing
                  </Link>
                  <Link className="dropdown-item" to="/manage-school-type">
                    School Type
                  </Link>
                  <Link className="dropdown-item" to="/manage-section">
                    Section
                  </Link>
                  <Link className="dropdown-item" to="/manage-standard">
                    Standard
                  </Link>
                  <Link className="dropdown-item" to="/manage-stream">
                    Stream
                  </Link>
                 
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="manageSchoolDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ color: "white" }}
                >
                  Manage School
                </a>
                <div
                  className="dropdown-menu bg-dark"
                  aria-labelledby="manageSchoolDropdown"
                >
                  <Link className="dropdown-item" to="/add-school">
                    General Info
                  </Link>
                  <Link className="dropdown-item" to="/add-school">
                    Affiliation Info
                  </Link>
                  <Link className="dropdown-item" to="/add-school">
                    Contact Info
                  </Link>
                  <Link className="dropdown-item" to="/school-list">
                    School List
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="manageStudentDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ color: "white" }}
                >
                  Manage Student
                </a>
                <div
                  className="dropdown-menu bg-dark"
                  aria-labelledby="manageStudentDropdown"
                >
                  <Link className="dropdown-item" to="/add-student">
                    Personal Details
                  </Link>
                  <Link className="dropdown-item" to="/add-student">
                    Previous School/College Details
                  </Link>
                  <Link className="dropdown-item" to="/add-student">
                    Upload Documents
                  </Link>
                  <Link className="dropdown-item" to="/add-student">
                    Contact Details
                  </Link>
                  <Link className="dropdown-item" to="/student-list">
                    Student List
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center">
            <button className="btn btn-outline-light" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AdminHeader;
