import RouterPage from "./components/routes/RouterPage";


function App() {
  return (
    <RouterPage />
  );
}

export default App;
