import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../common/Footer";

const StudentList = () => {
  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>StudentList</h2>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default StudentList