import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form } from "react-bootstrap";
import AdminHeader from "../../common/AdminHeader";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const District = () => {
  const [districts, setDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState({
    id: 0,
    stateID: 0,
    name: "",
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchDistricts();
    fetchStates();
  }, []);

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/DistrictMaster`);
      setDistricts(response.data);
    } catch (error) {
      toast.error("Failed to fetch districts.");
    }
  };

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/StateMaster`);
      setStates(response.data);
    } catch (error) {
      toast.error("Failed to fetch states.");
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormState({ id: 0, stateID: 0, name: "" });
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleEdit = (district) => {
    setFormState(district);
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this district?")) {
      try {
        await axios.delete(`${baseUrl}/api/DistrictMaster/${id}`);
        toast.success("District deleted successfully.");
        fetchDistricts();
      } catch (error) {
        toast.error("Failed to delete district.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formState.stateID === 0) {
        toast.error("Please select a State.");
        return;
      }
    try {
      if (editMode) {
        await axios.put(`${baseUrl}/api/DistrictMaster/${formState.id}`, formState);
        toast.success("District updated successfully.");
      } else {
        await axios.post(`${baseUrl}/api/DistrictMaster`, formState);
        toast.success("District added successfully.");
      }
      fetchDistricts();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to save district.");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>District</h2>
          <Button variant="primary" onClick={handleShowModal}>
            Add District
          </Button>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>State</th>
              <th>District Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {districts.map((district) => (
              <tr key={district.id}>
                <td>{district.id}</td>
                <td>{states.find((state) => state.id === district.stateID)?.name}</td>
                <td>{district.name}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEdit(district)}>
                    Edit
                  </Button>{" "}
                  <Button variant="danger" onClick={() => handleDelete(district.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit District" : "Add District"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control
                as="select"
                name="stateID"
                value={formState.stateID}
                onChange={handleInputChange}
                required
              >
                <option value="0">Select State</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>District Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formState.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editMode ? "Update" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />

      <Footer />
    </>
  );
};

export default District;
