import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Footer from "../../common/Footer";

const baseUrl = 'http://localhost:5166/';

const AddSchool = () => {
  const [formData, setFormData] = useState({
    schoolId: 0,
    organizationName: "",
    establishmentYear: "",
    schoolOrCollegeName: "",
    isNightSchoolOrCollege: false,
    location: "",
    udiseNo: "",
    ssC10thIndexNo: "",
    hsC12thIndexNo: "",
    grantType: "",
    streams: "",
    schoolType: "",
    attachType: "",
    standardType: "",
    availableStd: "",
    schoolOrCollegeTiming: "",
    createdBy: 0,
    createdOn: new Date().toISOString(),
    updatedBy: 0,
    updatedOn: new Date().toISOString(),
    isActive: true
  });

  const [dropdowns, setDropdowns] = useState({
    grantTypes: [],
    streams: [],
    schoolTypes: [],
    attachTypes: [],
    standardTypes: []
  });

  useEffect(() => {
    const fetchDropdowns = async () => {
      try {
        const [grantTypeRes, streamRes, schoolTypeRes, attachTypeRes, standardTypeRes] = await Promise.all([
          axios.get(`${baseUrl}api/GrantTypeMaster`),
          axios.get(`${baseUrl}api/StreamMaster`),
          axios.get(`${baseUrl}api/SchoolTypeMaster`),
          axios.get(`${baseUrl}api/AttachTypeMaster`),
          axios.get(`${baseUrl}api/StandardMaster`)
        ]);

        setDropdowns({
          grantTypes: grantTypeRes.data,
          streams: streamRes.data,
          schoolTypes: schoolTypeRes.data,
          attachTypes: attachTypeRes.data,
          standardTypes: standardTypeRes.data
        });
      } catch (error) {
        toast.error("Failed to fetch dropdown data");
      }
    };

    fetchDropdowns();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseUrl}api/SchoolGeneralInfo`, formData);
      toast.success("School information added successfully");
      setFormData({
        schoolId: 0,
        organizationName: "",
        establishmentYear: "",
        schoolOrCollegeName: "",
        isNightSchoolOrCollege: false,
        location: "",
        udiseNo: "",
        ssC10thIndexNo: "",
        hsC12thIndexNo: "",
        grantType: "",
        streams: "",
        schoolType: "",
        attachType: "",
        standardType: "",
        availableStd: "",
        schoolOrCollegeTiming: "",
        createdBy: 0,
        createdOn: new Date().toISOString(),
        updatedBy: 0,
        updatedOn: new Date().toISOString(),
        isActive: true
      });
    } catch (error) {
      toast.error("Failed to add school information");
    }
  };

  return (
    <>
      <AdminHeader />
      <Container className="mt-5">
        <h2>Add School</h2>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formOrganizationName">
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  type="text"
                  name="organizationName"
                  value={formData.organizationName}
                  onChange={handleInputChange}
                  placeholder="Enter organization name"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formEstablishmentYear">
                <Form.Label>Establishment Year</Form.Label>
                <Form.Control
                  type="number"
                  name="establishmentYear"
                  value={formData.establishmentYear}
                  onChange={handleInputChange}
                  placeholder="Enter establishment year"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSchoolOrCollegeName">
                <Form.Label>School/College Name</Form.Label>
                <Form.Control
                  type="text"
                  name="schoolOrCollegeName"
                  value={formData.schoolOrCollegeName}
                  onChange={handleInputChange}
                  placeholder="Enter school/college name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formIsNightSchoolOrCollege">
                <Form.Check
                  type="checkbox"
                  name="isNightSchoolOrCollege"
                  checked={formData.isNightSchoolOrCollege}
                  onChange={handleInputChange}
                  label="Is Night School/College?"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formLocation">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  placeholder="Enter location"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formUdiseNo">
                <Form.Label>UDISE No</Form.Label>
                <Form.Control
                  type="text"
                  name="udiseNo"
                  value={formData.udiseNo}
                  onChange={handleInputChange}
                  placeholder="Enter UDISE No"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formSSC10thIndexNo">
                <Form.Label>SSC 10th Index No</Form.Label>
                <Form.Control
                  type="text"
                  name="ssC10thIndexNo"
                  value={formData.ssC10thIndexNo}
                  onChange={handleInputChange}
                  placeholder="Enter SSC 10th Index No"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formHSC12thIndexNo">
                <Form.Label>HSC 12th Index No</Form.Label>
                <Form.Control
                  type="text"
                  name="hsC12thIndexNo"
                  value={formData.hsC12thIndexNo}
                  onChange={handleInputChange}
                  placeholder="Enter HSC 12th Index No"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formGrantType">
                <Form.Label>Grant Type</Form.Label>
                <Form.Control
                  as="select"
                  name="grantType"
                  value={formData.grantType}
                  onChange={handleInputChange}
                >
                  <option value="">Select Grant Type</option>
                  {dropdowns.grantTypes.map(type => (
                    <option key={type.id} value={type.name}>{type.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formStreams">
                <Form.Label>Streams</Form.Label>
                <Form.Control
                  as="select"
                  name="streams"
                  value={formData.streams}
                  onChange={handleInputChange}
                >
                  <option value="">Select Stream</option>
                  {dropdowns.streams.map(stream => (
                    <option key={stream.id} value={stream.name}>{stream.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSchoolType">
                <Form.Label>School Type</Form.Label>
                <Form.Control
                  as="select"
                  name="schoolType"
                  value={formData.schoolType}
                  onChange={handleInputChange}
                >
                  <option value="">Select School Type</option>
                  {dropdowns.schoolTypes.map(type => (
                    <option key={type.id} value={type.name}>{type.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formAttachType">
                <Form.Label>School Type</Form.Label>
                <Form.Control
                  as="select"
                  name="attachType"
                  value={formData.attachType}
                  onChange={handleInputChange}
                >
                  <option value="">Select School Type</option>
                  {dropdowns.attachTypes.map(type => (
                    <option key={type.id} value={type.name}>{type.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="formStandardType">
                <Form.Label>Standard Type</Form.Label>
                <Form.Control
                  as="select"
                  name="standardType"
                  value={formData.standardType}
                  onChange={handleInputChange}
                >
                  <option value="">Select Standard Type</option>
                  {dropdowns.standardTypes.map(type => (
                    <option key={type.id} value={type.name}>{type.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formAvailableStd">
                <Form.Label>Available Standard</Form.Label>
                <Form.Control
                  type="text"
                  name="availableStd"
                  value={formData.availableStd}
                  onChange={handleInputChange}
                  placeholder="Enter available standard"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formSchoolOrCollegeTiming">
                <Form.Label>School/College Timing</Form.Label>
                <Form.Control
                  type="text"
                  name="schoolOrCollegeTiming"
                  value={formData.schoolOrCollegeTiming}
                  onChange={handleInputChange}
                  placeholder="Enter timing"
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit">Add School</Button>
        </Form>
        <Footer />
      </Container>
      <ToastContainer />
    </>
  );
};

export default AddSchool;
