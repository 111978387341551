import React from "react";
import AdminHeader from "../common/AdminHeader";
import Footer from "../common/Footer";

const AdminDashboard = () => {
  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div>AdminDashboard</div>
      </div>
      <Footer />
    </>
  );
}

export default AdminDashboard;
