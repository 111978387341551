import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../common/Footer";

const baseUrl = 'http://localhost:5166/';

const StudentType = () => {
  const [studentTypes, setStudentTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // "add" or "edit"
  const [currentItem, setCurrentItem] = useState({ id: 0, name: "" });

  useEffect(() => {
    fetchStudentTypes();
  }, []);

  const fetchStudentTypes = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/StudentTypeMaster`);
      setStudentTypes(response.data);
    } catch (error) {
      toast.error("Failed to fetch student types");
    }
  };

  const handleAdd = () => {
    setCurrentItem({ id: 0, name: "" });
    setModalMode("add");
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalMode("edit");
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axios.delete(`${baseUrl}api/StudentTypeMaster/${id}`);
        toast.success("Item deleted successfully");
        fetchStudentTypes();
      } catch (error) {
        toast.error("Failed to delete item");
      }
    }
  };

  const handleSave = async () => {
    try {
      if (modalMode === "add") {
        await axios.post(`${baseUrl}api/StudentTypeMaster`, { ...currentItem, id: 0 });
        toast.success("Item added successfully");
      } else {
        await axios.put(`${baseUrl}api/StudentTypeMaster/${currentItem.id}`, currentItem);
        toast.success("Item updated successfully");
      }
      fetchStudentTypes();
      setShowModal(false);
    } catch (error) {
      toast.error("Failed to save item");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>Student Type</h2>
          <Button variant="primary" onClick={handleAdd}>Add New</Button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {studentTypes.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEdit(item)}>Edit</Button>
                  <Button variant="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalMode === "add" ? "Add New" : "Edit"} Student Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={currentItem.name}
                  onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
            <Button variant="primary" onClick={handleSave}>{modalMode === "add" ? "Add" : "Save"}</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default StudentType;
