import React from "react";
import { FooterName } from "../../Utility/constants";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      style={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "black",
        color: "#6c757d",
        textAlign: "center",
        padding: "10px 0",
        boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="container">
        <p className="mb-0" style={{color:"white"}}>
        Copyright &copy; 2017-{currentYear} {FooterName} | All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
