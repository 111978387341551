import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const Religion = () => {
  const [religions, setReligions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState({
    id: 0,
    name: "",
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchReligions();
  }, []);

  const fetchReligions = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/ReligionMaster`);
      setReligions(response.data);
    } catch (error) {
      toast.error("Failed to fetch religions.");
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormState({ id: 0, name: "" });
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleEdit = (religion) => {
    setFormState(religion);
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this religion?")) {
      try {
        await axios.delete(`${baseUrl}/api/ReligionMaster/${id}`);
        toast.success("Religion deleted successfully.");
        fetchReligions();
      } catch (error) {
        toast.error("Failed to delete religion.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editMode) {
        await axios.put(`${baseUrl}/api/ReligionMaster/${formState.id}`, formState);
        toast.success("Religion updated successfully.");
      } else {
        await axios.post(`${baseUrl}/api/ReligionMaster`, formState);
        toast.success("Religion added successfully.");
      }
      fetchReligions();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to save religion.");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>Religion</h2>
          <Button variant="primary" onClick={handleShowModal}>
            Add Religion
          </Button>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {religions.map((religion) => (
              <tr key={religion.id}>
                <td>{religion.id}</td>
                <td>{religion.name}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEdit(religion)}>
                    Edit
                  </Button>{" "}
                  <Button variant="danger" onClick={() => handleDelete(religion.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit Religion" : "Add Religion"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formState.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editMode ? "Update" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />

      <Footer />
    </>
  );
};

export default Religion;
