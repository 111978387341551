import React, { useEffect } from "react";
import MainHeader from "../common/MainHeader";
import Footer from "../common/Footer";

const HomePage = () => {
  useEffect(() => {
    // Clear user data from local storage
    localStorage.removeItem("userEmail");
    localStorage.removeItem("Role");
    localStorage.removeItem("userID");
  }, []);

  return (
    <div>
      <MainHeader />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
          <h1>Notifications</h1>
          Welcome to our online portal dedicated to providing you with a wide range of housing facilities. Our mission is to make your home search and living experience seamless and enjoyable. Here’s what we offer:
          <br></br> <br></br>
          <ul>
            <li>Comprehensive Property Listings</li>
            <li>User-Friendly Search Filters</li>
            <li>Secure Online Booking</li>
            <li>Expert Support and Guidance</li>
            <li>Community Insights</li>
            <li>Tenant and Landlord Services</li>
            <li>Maintenance and Support</li>
            <li>Flexible Payment Options</li>
            <li>Community Events and Notices</li>
            <li>Safety and Security</li>
            </ul>             
          </div>
          <div className="col-md-6">
            <h1>About Panacea School</h1>            
            <p style={{ textAlign: "justify" }}>
            Welcome to Panacea Society, your trusted partner in finding the perfect home. Our journey began with a 
            simple vision: to make the process of renting and owning a home as seamless and enjoyable as possible. 
            We understand that finding a home is more than just a transaction; it's about finding a place where memories 
            are made and lives are built.
            </p>
            <p style={{ textAlign: "justify" }}>
            Our mission is to revolutionize the housing industry by providing an unparalleled user experience through innovative technology and personalized services. We aim to bridge the gap between property seekers and their dream homes by offering a comprehensive platform that caters to all their needs.
            </p>
            <h3>What We Offer</h3>
            <ul>              
                <li><b>Diverse Property Listings:</b> Whether you're looking for a cozy apartment, a luxurious villa, or a commercial space, we have a wide range of properties to suit your needs and preferences.</li>
                <li><b>Advanced Search Tools:</b> Our user-friendly search filters allow you to easily find properties based on location, budget, size, and other essential criteria.</li>
                <li><b>Expert Guidance:</b> Our team of experienced real estate agents is always ready to assist you, providing expert advice and personalized recommendations.</li>
                <li><b>Secure and Efficient Processes:</b> From online bookings to digital document management, we ensure a smooth and secure transaction process for both renters and property owners.</li>
              </ul>
          </div>          
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
