import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../main-site/HomePage';
import Login from '../Login';
import AdminDashboard from '../admin/AdminDashboard';
import AttachType from '../admin/master/AttachType';
import BloodGroup from '../admin/master/BloodGroup';
import Category from '../admin/master/Category';
import SubCategory from '../admin/master/SubCategory';
import State from '../admin/master/State';
import Taluka from '../admin/master/Taluka';
import District from '../admin/master/District';
import CityVillage from '../admin/master/CityVillage';
import GrantType from '../admin/master/GrantType';
import MotherTounge from '../admin/master/MotherTounge';
import Religion from '../admin/master/Religion';
import SchoolLevel from '../admin/master/SchoolLevel';
import SchoolTimings from '../admin/master/SchoolTimings';
import Section from '../admin/master/Section';
import Standard from '../admin/master/Standard';
import StudentType from '../admin/master/StudentType';
import AddSchool from '../admin/school/AddSchool';
import AddStudent from '../admin/student/AddStudent';
import SchoolList from '../admin/school/SchoolList';
import StudentList from '../admin/student/StudentList';
import SchoolType from '../admin/master/SchoolType';
import Stream from '../admin/master/Stream';

const RouterPage = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/registration" element={<Registration />} /> */}

          {/* Admin */}
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/manage-attach-type" element={<AttachType />} />
          <Route path="/manage-blood-group" element={<BloodGroup />} />
          <Route path="/manage-category" element={<Category />} />
          <Route path="/manage-city" element={<CityVillage />} />
          <Route path="/manage-state" element={<State />} />
          <Route path="/manage-taluka" element={<Taluka />} />
          <Route path="/manage-district" element={<District />} />

          <Route path="/manage-grant-type" element={<GrantType />} />
          <Route path="/manage-monther-tounge" element={<MotherTounge />} />
          <Route path="/manage-religion" element={<Religion />} />
          <Route path="/manage-school-timing" element={<SchoolTimings />} />
          <Route path="/manage-school-level" element={<SchoolLevel />} />
          <Route path="/manage-school-type" element={<SchoolType />} />
          <Route path="/manage-section" element={<Section />} />
          <Route path="/manage-stream" element={<Stream />} />
          <Route path="/manage-standard" element={<Standard />} />
          <Route path="/manage-student-type" element={<StudentType />} />
          <Route path="/manage-sub-category" element={<SubCategory />} />
          <Route path="/add-school" element={<AddSchool />} />
          <Route path="/school-list" element={<SchoolList />} />
          <Route path="/add-student" element={<AddStudent />} />
          <Route path="/student-list" element={<StudentList />} />
        
        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default RouterPage;
