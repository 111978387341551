import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../common/AdminHeader";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../common/Footer";
import { baseUrl } from "../../../Utility/constants";

const GrantType = () => {
  const [grantTypes, setGrantTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState({
    id: 0,
    name: "",
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchGrantTypes();
  }, []);

  const fetchGrantTypes = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/GrantTypeMaster`);
      setGrantTypes(response.data);
    } catch (error) {
      toast.error("Failed to fetch grant types.");
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormState({ id: 0, name: "" });
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleEdit = (grantType) => {
    setFormState(grantType);
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this grant type?")) {
      try {
        await axios.delete(`${baseUrl}/api/GrantTypeMaster/${id}`);
        toast.success("Grant type deleted successfully.");
        fetchGrantTypes();
      } catch (error) {
        toast.error("Failed to delete grant type.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editMode) {
        await axios.put(`${baseUrl}/api/GrantTypeMaster/${formState.id}`, formState);
        toast.success("Grant type updated successfully.");
      } else {
        await axios.post(`${baseUrl}/api/GrantTypeMaster`, formState);
        toast.success("Grant type added successfully.");
      }
      fetchGrantTypes();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to save grant type.");
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2>GrantType</h2>
          <Button variant="primary" onClick={handleShowModal}>
            Add Grant Type
          </Button>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {grantTypes.map((grantType) => (
              <tr key={grantType.id}>
                <td>{grantType.id}</td>
                <td>{grantType.name}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEdit(grantType)}>
                    Edit
                  </Button>{" "}
                  <Button variant="danger" onClick={() => handleDelete(grantType.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit Grant Type" : "Add Grant Type"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formState.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {editMode ? "Update" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />

      <Footer />
    </>
  );
};

export default GrantType;
